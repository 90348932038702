<script setup>
const props = defineProps(["mode"])
const emit = defineEmits(["change-mode", "verified", "recover-email-not-found", "back"])
const form = ref()
const otp = ref("")
const emailError = ref(false)
const wrongCode = ref(false)
const email = ref("")

const cooldown = ref(0)
let cooldownInterval = null

const timeout = ref(0)
let timeoutInterval = null

const loading = ref(false)
const loadingCode = ref(false)

async function generateCode(verifyingEmail) {
  if (cooldown.value > 0) {
    return
  }
  timeout.value = 0
  email.value = verifyingEmail

  emailError.value = false
  wrongCode.value = false
  loadingCode.value = true
  try {
    await $fetch(`/pub/generate-${props.mode == "recover" ? "recover" : "verify"}-code`, {
      method: "POST",
      body: {
        email: email.value,
      },
    })
    otp.value = ""
    cooldown.value = 10
    startCooldown()
    timeout.value = 60
    startTimeout()
  } catch (err) {
    if (err.response) {
      switch (err.response.status) {
        case 401: // Something unexpected
          emit("change-mode", "login")
          break
        case 404: // Recover email not found
          emit("recover-email-not-found")
        case 409: // Already verified
          emit("change-mode", "login")
          break
        case 425: // generate on cooldown
          cooldown.value = parseInt(err.response._data)
          startCooldown()
          break
        case 428: // Failed to send email
          emailError.value = true
          break
      }
    } else {
      console.log(err)
    }
  }
  loadingCode.value = false
}

function startCooldown() {
  if (cooldownInterval) {
    clearInterval(cooldownInterval)
  }

  cooldownInterval = setInterval(() => {
    cooldown.value--
    if (cooldown.value <= 0) {
      clearInterval(cooldownInterval)
    }
  }, 1000)
}

function startTimeout() {
  if (timeoutInterval) {
    clearInterval(timeoutInterval)
  }

  timeoutInterval = setInterval(() => {
    timeout.value--
    if (timeout.value <= 0) {
      clearInterval(timeoutInterval)
    }
  }, 1000)
}

function stopTimeout() {
  if (timeoutInterval) {
    clearInterval(timeoutInterval)
  }
}

async function verify() {
  if (otp.value.length != 6 || timeout.value <= 0) {
    return
  }

  wrongCode.value = false
  loading.value = true
  try {
    const data = await $fetch(
      `/pub/${props.mode == "recover" ? "verify-recover-code" : "verify"}`,
      {
        method: "POST",
        body: {
          email: email.value,
          code: otp.value,
        },
      },
    )
    emit("verified", data)
  } catch (err) {
    if (err.response) {
      switch (err.response.status) {
        case 401: // Something unexpected
          emit("change-mode", "login")
          break
        case 404: // Recover email not found
          emit("recover-email-not-found")
        case 409: // Already verified
          emit("change-mode", "login")
          break
        case 412: // wrong verify code
          wrongCode.value = true
          break
        case 417: // no verify code expired or not generated
          stopTimeout()
          timeout.value = 0
          break
        case 428: // Failed to send email
          emailError.value = true
          break
      }
    } else {
      console.log(err)
    }
  }
  loading.value = false
}

function onBack() {
  $fetch(`/pub/logout`)
  emit("back")
}

defineExpose({
  generateCode,
})
</script>
<template>
  <v-form @submit.prevent="verify" ref="form">
    <v-row dense>
      <v-col cols="12" class="text-center"> Баталгаажуулах код илгээгдлээ. </v-col>
      <v-col cols="12" class="text-body-2 text-medium-emphasis text-center">
        Таны {{ email }} э-мэйл рүү илгээсэн<br />
        баталгаажуулах кодыг оруулна уу.
      </v-col>
      <v-col cols="12">
        <v-otp-input v-model="otp" autofocus :error="wrongCode"></v-otp-input>
      </v-col>
    </v-row>
    <v-row v-if="wrongCode">
      <v-col cols="12" class="text-center text-subtitle-2 text-error">
        Таны оруулсан код буруу байна.
      </v-col>
    </v-row>
    <v-row v-if="emailError">
      <v-col cols="12" class="text-center text-subtitle-2 text-error">
        Тань руу э-мэйл илгээхэд алдаа гарлаа. Та оруулсан э-мэйлээ зөв эсэхийг шалгана уу.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center text-subtitle-2 text-error">
        <span v-if="timeout > 0">{{ $formatSecond(timeout) }}</span>
        <span v-else>Хүчинтэй хугацаа дууссан байна. Та дахин код авна уу.</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn @click="generateCode(email)" color="blue" variant="text" :disabled="cooldown > 0">
          Дахин код авах{{ cooldown ? ` (${cooldown})` : `` }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-6" dense>
      <v-col cols="12">
        <v-btn
          block
          variant="flat"
          color="secondary"
          height="40"
          type="submit"
          :disabled="otp.length != 6 || timeout <= 0"
          :loading="loading"
        >
          Баталгаажуулах
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn block variant="tonal" color="secondary" height="40" @click="onBack"> Буцах </v-btn>
      </v-col>
    </v-row>
  </v-form>
  <v-overlay
    :model-value="loadingCode"
    persistent
    contained
    scrim="white"
    :opacity="1"
    class="d-flex align-center justify-center"
  >
    <v-progress-circular indeterminate color="primary" size="80" />
  </v-overlay>
</template>
