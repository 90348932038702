<script setup>
const route = useRoute()
const router = useRouter()
const constants = useConst()
const user = useUser()
const authDialog = inject("authDialog")
const categoryDialog = ref(false)
const categoriesMap = constants.categories.reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})
const city = useCity()
const filter = ref({
  q: route.query.q || "",
  cat: route.query.cat || "",
})

watch(
  () => route.query.cat,
  (v) => {
    if (filter.value.cat != v) {
      filter.value.cat = v
    }
  },
  { immediate: true },
)

watch(
  () => route.query.subcat,
  (v) => {
    if (filter.value.subcat != v) {
      filter.value.subcat = v
    }
  },
  { immediate: true },
)

watch(
  () => route.query.type,
  (v) => {
    if (filter.value.type != v) {
      filter.value.type = v
    }
  },
  { immediate: true },
)

const chosenCat = computed(() => {
  if (categoriesMap[filter.value.cat]) {
    let cat = categoriesMap[filter.value.cat]
    if (filter.value.subcat || filter.value.type) {
      if (cat.subcat?.length && filter.value.subcat) {
        for (let subcat of cat.subcat) {
          if (subcat.key == filter.value.subcat) {
            return subcat.name
          }
        }
      }

      if (cat.types?.length && filter.value.type) {
        for (let t of cat.types) {
          if (t == filter.value.type) {
            return t
          }
        }
      }

      return cat.name
    } else {
      return cat.name
    }
  }

  return "Ангилал"
})

function onChooseCat(cat, val = null, isChoosing = "subcat") {
  filter.value = { q: filter.value.q, cat }
  if (val == null) {
    return
  }
  switch (isChoosing) {
    case "subcat":
      filter.value.subcat = val
      break
    case "type":
      filter.value.type = val
      break
  }
}

function onAddAdvert() {
  if (user.value) {
    categoryDialog.value = true
  } else {
    authDialog.value = true
  }
}

function onSearch() {
  router.push({
    path: "/",
    query: {
      ...route.query,
      ...filter.value,
    },
  })
}
</script>
<template>
  <v-app-bar flat order="1">
    <v-container class="d-flex align-center bg-white py-6">
      <NuxtLink to="/" exact>
        <img src="/logo.svg" style="height: 48px" class="mr-6" />
      </NuxtLink>

      <div class="d-flex align-center flex-grow-1">
        <v-form class="flex-grow-1 flex-shrink-0" @submit.prevent="onSearch">
          <v-sheet border rounded class="d-flex align-center overflow-hidden">
            <v-text-field
              v-model="filter.q"
              class="flex-grow-1"
              placeholder="Та юу хайж байна вэ?"
              variant="solo"
              flat
              hide-details
              density="comfortable"
              clearable
              @click:clear="onSearch"
              name="search_q"
            />
            <v-divider vertical style="height: 40px; margin-top: 4px" />

            <v-btn
              variant="plain"
              :ripple="false"
              class="flex-grow-0 flex-shrink-0 text-truncate"
              style="min-width: 120px"
              append-icon="icon-chevron-down"
            >
              {{ chosenCat }}
              <v-menu activator="parent">
                <v-list>
                  <v-list-item @click="onChooseCat(category.key)" v-for="category of [{ name: 'Бүх зар', key: '' }, ...constants.categories]">
                    <v-list-item-title> {{ category.name }} </v-list-item-title>
                    <template #append>
                      <v-icon icon="icon-chevron-right" size="x-small" v-if="category.subcat?.length || category.types?.length"></v-icon>
                    </template>

                    <v-menu :open-on-focus="false" activator="parent" open-on-hover submenu location="end">
                      <v-list v-if="category.subcat?.length || category.types?.length" color="primary">
                        <v-list-item
                          @click="onChooseCat(category.key, subcat.key ? subcat.key : subcat, category.subcat?.length ? 'subcat' : 'type')"
                          v-for="(subcat, index) in category.subcat?.length ? category.subcat : category.types"
                          :key="index"
                        >
                          <v-list-item-title>
                            {{ subcat.name ? subcat.name : subcat }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>

            <v-btn style="height: 48px; min-width: 52px" tile class="flex-grow-0 px-2" color="#EBECEF" :ripple="false" variant="flat" type="submit">
              <v-icon>icon-search</v-icon>
            </v-btn>
          </v-sheet>
        </v-form>

        <v-select v-model="city" class="flex-grow-0" variant="solo" flat hide-details density="comfortable" :items="constants.cities">
          <template #prepend-inner>
            <v-icon color="primary" icon="icon-map-pin" />
          </template>
        </v-select>
      </div>

      <v-spacer v-if="!$vuetify.display.smAndDown" />

      <v-btn rounded color="secondary" variant="flat" class="mr-1" @click="onAddAdvert"> Зар нэмэх </v-btn>
      <template v-if="user">
        <v-btn variant="flat" icon class="ml-2" to="/profile/liked" exact>
          <v-avatar color="#737A8C" variant="tonal">
            <v-icon icon="icon-heart"></v-icon>
          </v-avatar>
        </v-btn>
        <v-btn variant="flat" icon class="ml-2" to="/profile" exact>
          <v-avatar color="#737A8C" variant="tonal">
            <v-img v-if="user.profile_picture" :alt="user.name" :src="user.profile_picture"></v-img>
            <v-icon v-else icon="icon-user"></v-icon>
          </v-avatar>
        </v-btn>
        <!-- {{ user.name }} -->
      </template>
      <template v-else>
        <v-btn rounded color="secondary" variant="text" height="52" @click="authDialog = true">
          <div class="d-flex flex-column align-center">
            <v-icon size="x-large">icon-user</v-icon>
            Нэвтрэх
          </div>
        </v-btn>
      </template>

      <AuthDialog v-model="authDialog" />
      <CategoryDialog v-model="categoryDialog" />
    </v-container>
  </v-app-bar>
</template>
