<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2087_171012)">
            <path
                d="M13.3661 14.4041C9.93113 14.4041 7.16406 11.637 7.16406 8.20206C7.16406 4.76707 9.93113 2 13.3661 2C16.8011 2 19.5682 4.76707 19.5682 8.20206C19.5682 11.637 16.8011 14.4041 13.3661 14.4041ZM13.3661 3.90833C10.9807 3.90833 9.07239 5.81665 9.07239 8.20206C9.07239 10.5875 10.9807 12.4958 13.3661 12.4958C15.7515 12.4958 17.6599 10.5875 17.6599 8.20206C17.6599 5.81665 15.7515 3.90833 13.3661 3.90833Z" />
            <path
                d="M19.2923 17.7006C19.006 17.7006 18.7198 17.6052 18.5289 17.3189L15.6664 13.5023C15.3802 13.1206 15.4756 12.4527 15.8573 12.1664C16.2389 11.8802 16.9068 11.9756 17.1931 12.3573L20.0556 16.1739C20.3418 16.5556 20.2464 17.2235 19.8648 17.5097C19.6739 17.6052 19.4831 17.7006 19.2923 17.7006Z" />
            <path
                d="M5.5 9.00016H1.33333C0.833333 9.00016 0.5 8.66683 0.5 8.16683C0.5 7.66683 0.833333 7.3335 1.33333 7.3335H5.5C6 7.3335 6.33333 7.66683 6.33333 8.16683C6.33333 8.66683 6 9.00016 5.5 9.00016Z" />
            <path
                d="M5.5 13.6667H1.33333C0.833333 13.6667 0.5 13.3333 0.5 12.8333C0.5 12.3333 0.833333 12 1.33333 12H5.5C6 12 6.33333 12.3333 6.33333 12.8333C6.33333 13.3333 6 13.6667 5.5 13.6667Z" />
            <path
                d="M5.5 4.66667H1.33333C0.833333 4.66667 0.5 4.33333 0.5 3.83333C0.5 3.33333 0.833333 3 1.33333 3H5.5C6 3 6.33333 3.33333 6.33333 3.83333C6.33333 4.33333 6 4.66667 5.5 4.66667Z" />
        </g>
        <defs>
            <clipPath id="clip0_2087_171012">
                <rect width="20" height="20" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
</template>
