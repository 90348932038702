<script setup>
const emit = defineEmits(["change-mode", "logged-in"])
const { $toast } = useNuxtApp()
const form1 = ref()
const form2 = ref()
const loading = ref(false)
const step = ref(1)
const verifyEl = ref()
const accountNotFound = ref()
const showPassword = ref(false)
const showRepeatPassword = ref(false)
const recoverData = ref({
  email: "",
  password: "",
  repeat_password: "",
})

const rules = ref({
  email: [(v) => !!v || "Э-мэйл хаягаа оруулна уу.", (v) => !!validateEmail(v) || "Э-мэйл хаягаа шалгана уу."],
  password: [(v) => !!v || "Нууц үгээ оруулна", (v) => v.length >= 8 || "Хэтэрхий богино байна."],
  repeat_password: [
    (v) => !!v || "Нууц үгээ оруулна",
    (v) => v == recoverData.value.password || "Эхний бичсэн нууц үгээс ялгаатай байна.",
  ],
})

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

async function recover() {
  const { valid } = await form1.value.validate()
  if (!valid) {
    return
  }

  accountNotFound.value = false
  step.value = 2
  nextTick(() => {
    verifyEl.value.generateCode(recoverData.value.email)
  })
}

async function resetPassword() {
  const { valid } = await form2.value.validate()
  if (!valid) {
    return
  }

  loading.value = true
  try {
    await $fetch("/pub/recover", {
      method: "POST",
      body: recoverData.value,
    })
    $toast.success("Нууц үг амжилттай солигдлоо.")
    emit("logged-in")
  } catch (err) {
    console.log(err)
  }
  loading.value = false
}

function onEmailNotFound() {
  step.value = 1
  accountNotFound.value = true
}

function onVerified() {
  step.value = 3
}

function onBack() {
  $fetch(`/pub/logout`)
  emit("change-mode", "login")
}
</script>
<template>
  <v-card class="py-9 px-6" rounded="lg">
    <h2 class="text-h5 text-center mb-8 font-weight-bold">
      <template v-if="step != 3">Нууц үг сэргээх</template>
      <template v-else>Нууц үг үүсгэх</template>
    </h2>
    <v-window v-model="step" class="overflow-visible" :touch="false">
      <v-window-item :value="1">
        <v-form @submit.prevent="recover" ref="form1">
          <v-row dense>
            <v-col cols="12">
              <label class="text-subtitle-2">
                Бүртгэлтэй э-мэйл хаягаа оруулна уу. <span class="text-error">*</span>
              </label>
              <v-text-field
                v-model="recoverData.email"
                :rules="rules.email"
                variant="outlined"
                type="email"
                hide-details="auto"
                density="comfortable"
                class="mt-1"
                placeholder="Email"
                name="recover_email"
              />
            </v-col>
          </v-row>
          <v-row v-if="accountNotFound">
            <v-col cols="12" class="text-center text-subtitle-2 text-error">
              Энэ э-мэйл дээр бүртгэл байхгүй байна.
            </v-col>
          </v-row>
          <v-row class="mt-6" dense>
            <v-col cols="12">
              <v-btn
                block
                variant="flat"
                color="secondary"
                height="40"
                type="submit"
                :disabled="!form1?.isValid"
                :loading="loading"
              >
                Баталгаажуулах код авах
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn block variant="tonal" color="secondary" height="40" @click="emit('change-mode', 'login')">
                Буцах
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-window-item>
      <v-window-item :value="2">
        <AuthVerifyCode
          ref="verifyEl"
          mode="recover"
          @verified="onVerified"
          @recover-email-not-found="onEmailNotFound"
          @change-mode="(v) => emit('change-mode', v)"
          @back="step = 1"
        />
      </v-window-item>
      <v-window-item :value="3">
        <v-form @submit.prevent="resetPassword" ref="form2">
          <v-row class="mt-6" dense>
            <v-col cols="12">
              <label class="text-subtitle-2"> Нууц үг <span class="text-error">*</span> </label>
              <v-text-field
                v-model="recoverData.password"
                :rules="rules.password"
                variant="outlined"
                :type="showPassword ? 'text' : 'password'"
                density="comfortable"
                class="mt-1"
                placeholder="Нууц үг оруулах"
                hide-details="auto"
                name="register_password"
                :append-inner-icon="showPassword ? 'icon-eye-off' : 'icon-eye'"
                persistent-hint
                hint="8 болон түүнээс дээш оронтой."
                @click:appendInner="showPassword = !showPassword"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-subtitle-2"> Нууц үг дахин оруулах <span class="text-error">*</span> </label>
              <v-text-field
                v-model="recoverData.repeat_password"
                :rules="rules.repeat_password"
                variant="outlined"
                :type="showRepeatPassword ? 'text' : 'password'"
                density="comfortable"
                class="mt-1"
                placeholder="Нууц үг оруулах"
                hide-details="auto"
                name="register_repassword"
                :append-inner-icon="showRepeatPassword ? 'icon-eye-off' : 'icon-eye'"
                @click:appendInner="showRepeatPassword = !showRepeatPassword"
              />
            </v-col>
          </v-row>
          <v-row class="mt-6" dense>
            <v-col cols="12">
              <v-btn
                block
                variant="flat"
                color="secondary"
                height="40"
                type="submit"
                :disabled="!form2?.isValid"
                :loading="loading"
              >
                Нууц үг шинэчлэх
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn block variant="tonal" color="secondary" height="40" @click="onBack"> Болих </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-window-item>
    </v-window>
  </v-card>
</template>
