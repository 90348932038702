<script setup>
const { $toast } = useNuxtApp()
const emit = defineEmits(["change-mode", "verify", "logged-in"])
const showPassword = ref(false)
const showRepeatPassword = ref(false)
const accountExists = ref(false)
const form = ref()
const loading = ref(false)
const step = ref(1)
const verifyEl = ref()
const registerData = ref({
  email: "",
  password: "",
  repeat_password: "",
  agreed_toc: false,
})

const rules = ref({
  email: [(v) => !!v || "Э-мэйл хаягаа оруулна уу.", (v) => !!validateEmail(v) || "Э-мэйл хаягаа шалгана уу."],
  password: [(v) => !!v || "Нууц үгээ оруулна", (v) => v.length >= 8 || "Хэтэрхий богино байна."],
  repeat_password: [
    (v) => !!v || "Нууц үгээ оруулна",
    (v) => v == registerData.value.password || "Эхний бичсэн нууц үгээс ялгаатай байна.",
  ],
  agree_toc: [(v) => !!v || "Зөвшөөрнө үү."],
})

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

async function register() {
  const { valid } = await form.value.validate()
  if (!valid) {
    return
  }

  loading.value = true
  try {
    const user = await $fetch("/pub/register", {
      method: "POST",
      body: registerData.value,
    })
    step.value = 2
    nextTick(() => {
      verifyEl.value.generateCode(user.Email)
    })
  } catch (err) {
    if (err.response) {
      if (err.response.status == 409) {
        accountExists.value = true
      }
    } else {
      console.log(err)
    }
  }
  loading.value = false
}

function onVerified() {
  $toast.success("Амжилттай нэвтэрлээ.")
  emit("logged-in")
}
</script>
<template>
  <v-card class="py-9 px-6" rounded="lg">
    <h2 class="text-h5 text-center mb-8 font-weight-bold">Бүртгүүлэх</h2>
    <v-window v-model="step" class="overflow-visible" :touch="false">
      <v-window-item :value="1">
        <v-form @submit.prevent="register" ref="form">
          <v-row dense>
            <v-col cols="12">
              <label class="text-subtitle-2"> Э-мэйл хаягаа оруулна уу. <span class="text-error">*</span> </label>
              <v-text-field
                v-model="registerData.email"
                :rules="rules.email"
                variant="outlined"
                type="email"
                hide-details="auto"
                density="comfortable"
                class="mt-1"
                placeholder="Email"
                name="email"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-subtitle-2"> Нууц үг <span class="text-error">*</span> </label>
              <v-text-field
                v-model="registerData.password"
                :rules="rules.password"
                variant="outlined"
                :type="showPassword ? 'text' : 'password'"
                density="comfortable"
                class="mt-1"
                placeholder="Нууц үг оруулах"
                hide-details="auto"
                name="register_password"
                :append-inner-icon="showPassword ? 'icon-eye-off' : 'icon-eye'"
                persistent-hint
                hint="8 болон түүнээс дээш оронтой."
                @click:appendInner="showPassword = !showPassword"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-subtitle-2"> Нууц үг дахин оруулах <span class="text-error">*</span> </label>
              <v-text-field
                v-model="registerData.repeat_password"
                :rules="rules.repeat_password"
                variant="outlined"
                :type="showRepeatPassword ? 'text' : 'password'"
                density="comfortable"
                class="mt-1"
                placeholder="Нууц үг оруулах"
                hide-details="auto"
                name="register_repassword"
                :append-inner-icon="showRepeatPassword ? 'icon-eye-off' : 'icon-eye'"
                @click:appendInner="showRepeatPassword = !showRepeatPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <v-checkbox v-model="registerData.agreed_toc" :rules="rules.agree_toc" hide-details color="primary">
                <template #label="{ label, props }">
                  <label class="text-subtitle-2 cursor-pointer text-black" v-bind="props">
                    <NuxtLink target="_blank" href="/terms" class="text-decoration-none">Үйлчилгээний нөхцөл</NuxtLink>
                    ба
                    <NuxtLink target="_blank" href="/privacy-policy" class="text-decoration-none"
                      >нууцлалын бодлого </NuxtLink
                    >-ыг хүлээн зөвшөөрч байна.
                  </label>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="accountExists">
            <v-col cols="12" class="text-center text-subtitle-2 text-error"> Э-мэйл хаяг бүртгэлтэй байна. </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                block
                variant="flat"
                color="secondary"
                height="40"
                type="submit"
                :disabled="!form?.isValid"
                :loading="loading"
              >
                Бүртгүүлэх
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col class="text-subtitle-2 d-flex align-center justify-center">
              Та бүртгэлтэй бол
              <v-btn
                color="primary"
                class="ml-1 px-1"
                variant="text"
                density="compact"
                @click="emit('change-mode', 'login')"
              >
                Нэвтрэх
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-window-item>
      <v-window-item :value="2">
        <AuthVerifyCode
          ref="verifyEl"
          mode="register"
          @verified="onVerified"
          @change-mode="(v) => emit('change-mode', v)"
          @back="step = 1"
        />
      </v-window-item>
    </v-window>
  </v-card>
</template>
