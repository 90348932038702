<script setup>
const dialog = defineModel()

const mode = ref("login")

watch(dialog, (v) => {
  if (v) {
    mode.value = "login"
  }
})

async function onLoggedIn() {
  mode.value = "login"
  dialog.value = false
  await useFetchMe()
}
</script>
<template>
  <v-dialog v-model="dialog" max-width="460" scrollable :fullscreen="$vuetify.display.smAndDown">
    <v-btn
      v-if="$vuetify.display.smAndDown"
      style="position: absolute; top: 10px; right: 10px; z-index: 1"
      size="small"
      variant="text"
      icon="icon-x"
      @click="dialog = false"
    ></v-btn>
    <AuthLogin v-if="mode == 'login'" @change-mode="(v) => (mode = v)" @logged-in="onLoggedIn" />
    <AuthRegister v-if="mode == 'register'" @change-mode="(v) => (mode = v)" @logged-in="onLoggedIn" />
    <AuthRecover v-if="mode == 'recover'" @change-mode="(v) => (mode = v)" @logged-in="onLoggedIn" />
  </v-dialog>
</template>
