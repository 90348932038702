<script setup>
const user = useUser()
const authDialog = ref(false)

function onClick() {
    if (!user.value) {
        authDialog.value = true
    }
}
</script>
<template>
    <v-bottom-navigation color="primary" :active="$vuetify.display.smAndDown">
        <v-btn class="px-0" to="/">
            <v-icon icon="icon-house" />

            <span>Нүүр</span>
        </v-btn>

        <v-btn class="px-0" to="/search">
            <IconSearchList />

            <span>Зар хайх</span>
        </v-btn>

        <v-btn class="px-0" :to="user ? '/add' : null" @click="onClick">
            <IconCameraPlus />

            <span>Зар нэмэх</span>
        </v-btn>

        <v-btn class="px-0" :to="user ? '/profile/liked' : null" @click="onClick">
            <v-icon icon="icon-heart" />

            <span>Хадгалсан</span>
        </v-btn>

        <v-btn class="px-0" :to="user ? '/profile' : null" @click="onClick">
            <v-icon icon="icon-user" />

            <span> {{ user ? 'Миний' : 'Нэвтрэх' }} </span>
        </v-btn>
    </v-bottom-navigation>
    <AuthDialog v-model="authDialog" />
</template>
<style scoped>
.logo-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: grey;
}
</style>