<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(["update:modelValue"])
const router = useRouter()

const step = ref(1)
const categories = useConst().categories
const chosenCat = ref()

const dialog = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
    if (!v) {
      step.value = 1
    }
  },
})

function onChooseCat(cat) {
  if (cat.subcat) {
    chosenCat.value = cat
    step.value = 2
  } else {
    router.push(`/add/${cat.key}`)
    dialog.value = false
  }
}

function onChooseSubCat(subcat) {
  router.push({
    path: `/add/${chosenCat.value.key}`,
    query: {
      subcategory: subcat.key
    },
  })
  dialog.value = false
}
</script>
<template>
  <v-dialog v-model="dialog" max-width="640">
    <v-card class="categoryCard" rounded="lg">
      <v-card-title class="position-absolute d-flex" style="width: 100%">
        <v-btn v-if="step > 1" icon="icon-arrow-left" variant="text" @click="step--"></v-btn>
        <v-spacer />
        <v-btn icon="icon-x" variant="text" @click="dialog = false"></v-btn>
      </v-card-title>
      <!-- HEADER -->
      <v-card-text class="text-center mt-8">
        <h2 v-if="step == 1">Зар нэмэх</h2>
        <h2 v-else>{{ chosenCat.name }}</h2>
        <p class="text-body-1 font-weight-medium text-medium-emphasis mt-3">Та аль төрөлд хамаарах зар оруулах вэ?</p>
      </v-card-text>
      <v-card-text>
        <v-window v-model="step">
          <v-window-item :value="1">
            <!-- BODY : MAIN CATEGORIES -->
            <div class="d-flex flex-wrap align-center justify-center">
              <v-card v-for="category in categories" :key="category.key" class="text-center px-2 py-4 ma-2" flat
                rounded="lg" color="#F8F8F8" min-width="130" @click="onChooseCat(category)">
                <img :src="category.icon" height="40" />
                <div class="text-subtitle-2">
                  {{ category.name }}
                </div>
              </v-card>
            </div>
          </v-window-item>

          <!-- BODY : SUB CATEGORIES -->
          <v-window-item :value="2">
            <div class="d-flex flex-column align-center" style="margin: 55px 0">
              <v-card v-for="subcategory in chosenCat.subcat" :key="subcategory.key"
                @click="onChooseSubCat(subcategory)" flat rounded="lg" width="340" color="#EDEDED"
                class="text-center font-weight-medium px-8 py-5 mb-4">
                <span style="font-size: 16px; letter-spacing: -0.16px;">
                  {{ subcategory.name }}
                </span>
              </v-card>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
