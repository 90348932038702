<script setup>
const emit = defineEmits(["change-mode", "logged-in"])
const form = ref()
const showPassword = ref(false)
const emailOrPasswordWrong = ref(false)
const step = ref(1)
const verifyEl = ref()
const loginData = ref({
  email: "",
  password: "",
  remember_me: false,
})
const loading = ref(false)

const rules = ref({
  email: [(v) => !!v || "Э-мэйл хаягаа оруулна уу.", (v) => !!validateEmail(v) || "Э-мэйл хаягаа шалгана уу."],
  password: [(v) => !!v || "Нууц үгээ оруулна", (v) => v.length >= 8 || "Хэтэрхий богино байна."],
})

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

watch(
  () => loginData.value.email + loginData.value.password,
  () => {
    if (emailOrPasswordWrong.value) {
      emailOrPasswordWrong.value = false
    }
  },
)

async function loginWithEmail() {
  const { valid } = await form.value.validate()
  if (!valid) {
    return
  }

  loading.value = true
  try {
    const user = await $fetch("/pub/login", {
      method: "POST",
      body: loginData.value,
    })
    if (!user.is_verified) {
      step.value = 2
      nextTick(() => {
        verifyEl.value.generateCode(user.Email)
      })
    } else {
      emit("logged-in")
    }
  } catch (err) {
    if (err.response) {
      if (err.response.status == 409) {
        emailOrPasswordWrong.value = true
      }
    } else {
      console.log(err)
    }
  }
  loading.value = false
}

function onVerified() {
  emit("logged-in")
}
</script>
<template>
  <v-card class="py-9 px-6" rounded="lg">
    <h2 class="text-h5 text-center mb-8 font-weight-bold">Нэвтрэх</h2>
    <v-window v-model="step" class="overflow-visible" :touch="false">
      <v-window-item :value="1">
        <v-row dense>
          <v-col>
            <v-btn block variant="text" border height="40" href="/pub/auth/google/login">
              <img class="mr-1" src="/brands/google_sign_in.svg" width="20" height="20" />
              Google
            </v-btn>
          </v-col>
          <!-- <v-col>
                        <v-btn block variant="text" border height="40" href="/pub/auth/facebook/login">
                            <img class="mr-1" src="/brands/facebook_sign_in.svg" width="20" height="20" />
                            Facebook
                        </v-btn>
                    </v-col> -->
        </v-row>
        <v-row dense class="my-6">
          <v-col class="d-flex align-center text-subtitle-2 text-medium-emphasis">
            <v-divider />
            <span class="px-2">Эсвэл</span>
            <v-divider />
          </v-col>
        </v-row>
        <v-form @submit.prevent="loginWithEmail" ref="form">
          <v-row dense>
            <v-col cols="12">
              <label class="text-subtitle-2"> Э-мэйл хаягаа оруулна уу. <span class="text-error">*</span> </label>
              <v-text-field
                v-model="loginData.email"
                :rules="rules.email"
                autocomplete
                variant="outlined"
                type="email"
                name="email"
                hide-details="auto"
                density="comfortable"
                class="mt-1"
                placeholder="Email"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-subtitle-2"> Нууц үг <span class="text-error">*</span> </label>
              <v-text-field
                v-model="loginData.password"
                :rules="rules.password"
                autocomplete
                variant="outlined"
                :type="showPassword ? 'text' : 'password'"
                density="comfortable"
                class="mt-1"
                placeholder="Нууц үг оруулах"
                hide-details="auto"
                name="current-password"
                :append-inner-icon="showPassword ? 'icon-eye-off' : 'icon-eye'"
                @click:appendInner="showPassword = !showPassword"
              />
            </v-col>
          </v-row>
          <v-row v-if="emailOrPasswordWrong">
            <v-col cols="12" class="text-center text-subtitle-2 text-error"> Э-мэйл хаяг эсвэл нууц үг буруу байна. </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex align-center">
              <!-- <v-checkbox v-model="loginData.remember_me" color="primary" label="Сануулах" hide-details>
                    <template #label="{ label, props }">
                        <label class="text-subtitle-2 cursor-pointer text-black" v-bind="props">{{ label }}</label>
                    </template>
</v-checkbox> -->
              <v-spacer />
              <v-btn variant="text" color="#666666" @click="emit('change-mode', 'recover')">Нууц үг мартсан?</v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-btn block variant="flat" color="secondary" height="40" type="submit" :disabled="!form?.isValid" :loading="loading"> Нэвтрэх </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn block variant="tonal" color="secondary" height="40" @click="emit('change-mode', 'register')"> Бүртгүүлэх </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-window-item>
      <v-window-item :value="2">
        <AuthVerifyCode ref="verifyEl" mode="register" @verified="onVerified" @change-mode="(v) => emit('change-mode', v)" @back="step = 1" />
      </v-window-item>
    </v-window>
  </v-card>
</template>
