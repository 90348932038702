<script setup>
const route = useRoute()
const router = useRouter()
const constants = useConst()
const authDialog = inject("authDialog")
const city = useCity()
const filter = ref({
  q: route.query.q || "",
})

function onSearch() {
  router.push({
    path: "/",
    query: {
      ...route.query,
      ...filter.value,
    },
  })
}

const showAppBar = computed(() => {
  if (route.path == "/" && (route.query.cat || route.query.q)) {
    // Showing filter header
    return false
  }
  if (route.path.startsWith("/add/") && route.params?.category) {
    // Showing add header
    return false
  }
  if (route.path.startsWith("/profile")) {
    // profile
    return false
  }
  if (route.path.startsWith("/ad/")) {
    // profile
    return false
  }
  if (route.path.startsWith("/edit/")) {
    // profile
    return false
  }
  return true
})
</script>
<template>
  <v-app-bar flat v-if="showAppBar" order="1">
    <v-container class="d-flex align-center bg-white">
      <div class="d-flex align-center flex-grow-1">
        <v-form class="flex-grow-1 flex-shrink-0" @submit.prevent="onSearch">
          <v-text-field
            v-model="filter.q"
            prepend-inner-icon="icon-search"
            class="flex-grow-1"
            placeholder="Та юу хайж байна вэ?"
            variant="outlined"
            hide-details
            density="comfortable"
            clearable
            @click:clear="onSearch"
            name="search_q"
          >
            <template #append-inner>
              <v-menu activator="parent">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" icon="icon-map-pin" color="primary" />
                </template>
                <v-list color="primary">
                  <v-list-item v-for="c in constants.cities" @click="city = c" :active="city == c">
                    <v-list-item-title>{{ c }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
        </v-form>
      </div>
    </v-container>
    <AuthDialog v-model="authDialog" />
  </v-app-bar>
</template>
<style scoped>
.logo-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: grey;
}
</style>
