<script setup>
const loading = ref(false)
const nuxtApp = useNuxtApp()
const authDialog = ref(false)
provide("authDialog", authDialog)

nuxtApp.hook("page:start", () => {
  loading.value = true
})

nuxtApp.hook("page:finish", () => {
  loading.value = false
})
</script>
<template>
  <v-layout ref="app" style="min-height: 100vh">
    <NavigationTopMobile v-if="$vuetify.display.smAndDown" />
    <NavigationTop v-else />

    <slot></slot>

    <ClientOnly>
      <Loader v-if="loading" />
    </ClientOnly>
    <NavigationBottomMobile />
  </v-layout>
</template>
<style>
.banner-link,
.banner-link * {
  cursor: pointer !important;
}
</style>
